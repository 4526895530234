<template>
  <div class="card" style="margin-top:0;">
    <div class="card-header card-header-icon card-header-rose">
      <div class="card-icon">
        <i class="material-icons">notes</i>
      </div>
      <h3 class="card-title">SERVICIOS WELLBOAT</h3>
    </div>
    <div class="card-body">
      <v-row class="mx-0">
        <v-col cols="2">
          <v-select prepend-icon="mdi-navigation" v-model="opcionReporte" :items="[
            { id: 1, nombre: 'Calendario' },
            { id: 2, nombre: 'Financiero' },
          ]" item-text="nombre" item-value="id" label="Tipo de Reporte" color="blue darken-3"
            @change="setCierre"></v-select>
        </v-col>
        <v-col cols="2" v-if="opcionReporte != 2">
          <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="inicio_formated" label="Fecha de Inicio" prepend-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="inicio" @input="menu1 = false" locale="es-cl" prev-icon="chevron_left"
              next-icon="chevron_right"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2" v-if="opcionReporte != 2">
          <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="termino_formated" label="Fecha de termino" prepend-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="termino" @input="menu2 = false" locale="es-cl" prev-icon="chevron_left"
              next-icon="chevron_right"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3" v-if="opcionReporte == 2">
          <v-select prepend-icon="mdi-navigation" v-model="cierre" :items="cierres" item-text="nombre" item-value="id"
            label="Elija periodo de cierre" color="blue darken-3"></v-select>
        </v-col>
        <v-col cols="2">
          <!-- <v-select prepend-icon="mdi-navigation" v-model="opcionFiltro"
              :items="[{ id: 1, nombre: 'Todos' }, { id: 2, nombre: 'Cosecha' }, { id: 3, nombre: 'Smolt' }]" item-text="nombre"
              item-value="id" label="Filtro Reporte" color="blue darken-3"></v-select> -->

          <v-select prepend-icon="mdi-navigation" v-model="opcionFiltro" :items="faenas" item-text="nombre"
            item-value="id" label="Filtro Reporte" color="blue darken-3"></v-select>
        </v-col>
        <v-col class="text-right" cols="4">
          <v-btn class="mx-2" dark color="blue darken-1" @click="loadPartes"><i class="material-icons">cached</i>
            Generar</v-btn>
          <v-btn class="mx-2" dark @click="reporte_sw_nuevo_a_excel" v-if="can_download" color="green"><i
              class="material-icons">text_snippet</i> Excel</v-btn>
        </v-col>
      </v-row>

      <spinner v-if="spinner"></spinner>

      <v-data-table :headers="partesListHeaders" :items="partesListBody" no-data-text="No hay partes" :search="search"
        fixed-header :height="table_height" :header-props="{ sortIcon: 'arrow_upward' }" :hide-default-footer="true"
        disable-pagination>
        <template v-slot:item="{ item }">
          <tr :class="`${item.select ? 'blue-grey lighten-4' : ''}`" :key="item.id"
            v-on:dblclick="item.select = !item.select">
            <td>
              <a @click="save(item)" v-if="permisos.grabar == 1"><i class="material-icons">save</i></a>
              <a @click="downloadPDF(item)" v-if="item.pdf != null"><i class="material-icons">picture_as_pdf</i></a>
            </td>
            <td>{{ item.folio }}</td>
            <td>{{ item.year }}</td>
            <td>{{ item.month }}</td>
            <td>{{ item.tipo_parte }}</td>
            <td>{{ item.zarpe | format_date_spanish }}</td>
            <td>
              <date-picker v-model="item.fecha_viaje" format="DD-MM-YYYY"></date-picker>
            </td>
            <td>{{ item.wellboat }}</td>
            <td>
              <v-text-field v-model="item.empresa" label="Empresa" readonly></v-text-field>
            </td>
            <td>
              <v-text-field v-model="item.guia_despacho" label="Guia Despacho"></v-text-field>
            </td>
            <td>
              <!-- centro de cultivo -->
              <v-text-field v-if="item.tipo_origen == 1" v-model="item.centro_carga"
                @click="showModalCentro(item, 'carga')"></v-text-field>

              <!-- muelles -->
              <v-select v-if="item.tipo_origen == 3" v-model="item.centro_carga" :items="muelles" item-text="nombre"
                item-value="nombre" dense></v-select>
            </td>

            <td>
              <v-text-field v-model="item.jaulas_centro" label="Jaula Origen"></v-text-field>
            </td>

            <td>
              <!-- centro de cultivo -->
              <v-text-field v-if="item.tipo_destino == 1" v-model="item.centro_descarga"
                @click="showModalCentro(item, 'descarga')"></v-text-field>

              <!-- centro de acopio -->
              <v-select v-if="item.tipo_destino == 2" v-model="item.centro_descarga" :items="acopios" item-text="nombre"
                item-value="nombre" return-object dense></v-select>

              <!-- planta de proceso -->
              <v-select v-if="item.tipo_destino == 4" v-model="item.centro_descarga" :items="plantas" item-text="nombre"
                item-value="nombre" return-object dense></v-select>

              <!-- claudio -->
              <!-- <v-select v-if="item.tipo_destino == 3" v-model="item.centro_descarga" :items="plantas" item-text="nombre"
                  item-value="nombre" return-object dense></v-select> -->
              <!-- claudio -->
            </td>
            <td>
              <v-text-field v-model="item.jaulas_acopio" label="Jaula Destino"></v-text-field>
            </td>
            <td>
              <v-select v-model="item.especie" :items="especies" item-text="nombre" item-value="nombre" label="Especie"
                placeholder="Seleccione especie" dense></v-select>
            </td>
            <td>
              <v-text-field v-model="item.peces_guia" label="N° Peces Guía"></v-text-field>
            </td>

            <td>
              <v-text-field v-model="item.peces_contador" label="N° Peces Contador"
                @change="calcularDiferenciaConteo(item)"></v-text-field>
            </td>

            <td>
              <v-text-field v-model="item.peces_mort_viaje" label="N° Peces Mort Viaje"
                @input="calcularPecesTotalViaje(item)"></v-text-field>
            </td>

            <td>
              <v-text-field v-model="item.peces_mort_centro" label="N° Peces Mort Centro"
                @input="calcularPecesTotalViaje(item)"></v-text-field>
            </td>

            <td>
              <v-text-field v-model="item.peces_planta" label="N° Peces Planta"
                @input="calcularPecesTotalViaje(item)"></v-text-field>
            </td>

            <td>
              {{ formatNumber(item.peces_total, 0) }}
            </td>

            <td>
              {{ formatNumber(item.diferencia_conteo_numero, 0) }}
            </td>

            <td>
              {{ formatNumber(item.diferencia_conteo_porcentaje, 2) }}
            </td>

            <td>
              {{ formatNumber(item.talla_pez_centro, 2) }}
            </td>

            <td>
              <v-text-field v-model="item.talla_pez_planta" label="Talla Pez Planta"
                @change="formatNumber(calcularToneladasCompensar(item), 2)"></v-text-field>
            </td>

            <td>
              {{ formatNumber(item.desviacion_maxima, 2) }}
            </td>

            <td>
              {{ formatNumber(item.dif_planta_vs_desv_maxima, 2) }}
            </td>

            <td>
              <v-text-field v-model="item.ton_compensar" label="Ton Compensar"></v-text-field>
            </td>

            <td>
              {{ formatNumber(item.talla_pez_blod, 3) }}
            </td>

            <td>
              {{ formatNumber(item.talla_total_planta, 3) }}
            </td>

            <td>
              {{ formatNumber(item.total_carga_biomasa, 0) }}
            </td>

            <td>
              {{ formatNumber(item.total_carga_densidad, 2) }}
            </td>

            <td>
              <v-datetime-picker v-model="item.carga_eta" dateFormat="dd-MM-yyyy" :datePickerProps="{
                locale: 'es-es',
                prevIcon: 'chevron_left',
                nextIcon: 'chevron_right',
              }" :timePickerProps="{ format: '24hr' }" @input="calcularTiempoCarga(item)">
                <template slot="dateIcon">
                  <v-icon>calendar_today</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>access_time</v-icon>
                </template>
              </v-datetime-picker>
            </td>

            <td>
              <v-datetime-picker v-model="item.carga_inicio" dateFormat="dd-MM-yyyy" :datePickerProps="{
                locale: 'es-es',
                prevIcon: 'chevron_left',
                nextIcon: 'chevron_right',
              }" :timePickerProps="{ format: '24hr' }" @input="calcularTiempoCarga(item)">
                <template slot="dateIcon">
                  <v-icon>calendar_today</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>access_time</v-icon>
                </template>
              </v-datetime-picker>
            </td>

            <td>
              <v-datetime-picker v-model="item.carga_termino" dateFormat="dd-MM-yyyy" :datePickerProps="{
                locale: 'es-es',
                prevIcon: 'chevron_left',
                nextIcon: 'chevron_right',
              }" :timePickerProps="{ format: '24hr' }" @input="calcularTiempoCarga(item)">
                <template slot="dateIcon">
                  <v-icon>calendar_today</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>access_time</v-icon>
                </template>
              </v-datetime-picker>
            </td>

            <td>
              <v-datetime-picker v-model="item.traslado_inicio" dateFormat="dd-MM-yyyy" :datePickerProps="{
                locale: 'es-es',
                prevIcon: 'chevron_left',
                nextIcon: 'chevron_right',
              }" :timePickerProps="{ format: '24hr' }" @input="calcularTiempoTraslado(item)">
                <template slot="dateIcon">
                  <v-icon>calendar_today</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>access_time</v-icon>
                </template>
              </v-datetime-picker>
            </td>

            <td>
              <v-datetime-picker v-model="item.traslado_termino" dateFormat="dd-MM-yyyy" :datePickerProps="{
                locale: 'es-es',
                prevIcon: 'chevron_left',
                nextIcon: 'chevron_right',
              }" :timePickerProps="{ format: '24hr' }" @input="calcularTiempoTraslado(item)">
                <template slot="dateIcon">
                  <v-icon>calendar_today</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>access_time</v-icon>
                </template>
              </v-datetime-picker>
            </td>

            <td>
              <v-datetime-picker v-model="item.descarga_eta" dateFormat="dd-MM-yyyy" :datePickerProps="{
                locale: 'es-es',
                prevIcon: 'chevron_left',
                nextIcon: 'chevron_right',
              }" :timePickerProps="{ format: '24hr' }" @input="calcularTiempoDescarga(item)">
                <template slot="dateIcon">
                  <v-icon>calendar_today</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>access_time</v-icon>
                </template>
              </v-datetime-picker>
            </td>

            <td>
              <v-datetime-picker v-model="item.descarga_inicio" dateFormat="dd-MM-yyyy" :datePickerProps="{
                locale: 'es-es',
                prevIcon: 'chevron_left',
                nextIcon: 'chevron_right',
              }" :timePickerProps="{ format: '24hr' }" @input="calcularTiempoDescarga(item)">
                <template slot="dateIcon">
                  <v-icon>calendar_today</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>access_time</v-icon>
                </template>
              </v-datetime-picker>
            </td>

            <td>
              <v-datetime-picker v-model="item.descarga_termino" dateFormat="dd-MM-yyyy" :datePickerProps="{
                locale: 'es-es',
                prevIcon: 'chevron_left',
                nextIcon: 'chevron_right',
              }" :timePickerProps="{ format: '24hr' }" @input="calcularTiempoDescarga(item)">
                <template slot="dateIcon">
                  <v-icon>calendar_today</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>access_time</v-icon>
                </template>
              </v-datetime-picker>
            </td>

            <td>
              {{ item.carga_espera }}
            </td>

            <td>
              {{ item.carga_duracion }}
            </td>

            <td>
              {{ item.traslado_espera }}
            </td>

            <td>
              {{ item.traslado_duracion }}
            </td>

            <td>
              {{ item.descarga_espera }}
            </td>

            <td>
              {{ item.descarga_duracion }}
            </td>

            <td>
              {{ item.total }}
            </td>

            <td>
              <v-text-field v-model="item.tecnico_wellboat" label="Tecnico a Cargo"></v-text-field>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <!--
      <v-row>
          <v-col cols="8"></v-col>
          <v-col cols="4" class="text-right">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2"
                  color="green darken-1"
                  dark
                  right
                  fab
                  small
                  @click="upload = !upload"
                  v-bind="attrs"
                  v-on="on"
                >
                  <i class="material-icons">upload</i>
                </v-btn>
              </template>
              <span>Subir excel histórico de Servicios Wellboat</span>
            </v-tooltip>
          </v-col>
        </v-row>
        -->
    <v-snackbar v-model="snackbar" :timeout="5000" :color="color">
      {{ validation_message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Modal upload -->
    <v-dialog v-model="upload" width="600px">
      <v-card>
        <v-card-title class="green darken-1">
          Subir listado de acopios desde excel
        </v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-file-input v-model="file" accept=".xls, .xlsx" label="Archivo Excel"></v-file-input>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="upload = false">Cancelar</v-btn>
          <v-btn color="primary" @click="uploadServiciosWellboat">Subir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal upload-->
    <!-- Modal Centro -->
    <v-dialog v-model="elegirCentro" width="600px">
      <v-card>
        <v-card-title class="green darken-1">
          Elija empresa y centro
        </v-card-title>
        <v-card-text>
          <v-row class="mx-2 my-2">
            <v-select v-model="empresa" :items="empresas" item-text="razon_social" item-value="id" label="Empresa"
              return-object @change="centros = empresa.centros">
            </v-select>
          </v-row>
          <v-row class="mx-2 my-2">
            <v-select v-model="centro" :items="centros" item-text="nombre" item-value="id" label="Centro" return-object>
            </v-select>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="elegirCentro = false">Cancelar</v-btn>
          <v-btn color="primary" @click="actualizarCentro">Actualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal Centro-->
  </div>
</template>
<style scoped>
.table-wellboat {
  font-size: 16pt;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

.card {
  margin-top: 0;
}

.card .card-header-rose .card-icon {
  background: #263238;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(32, 30, 31, 0.822);
}

.mx-datepicker {
  width: 130px;
}
</style>
<script>
import { mapState, mapMutations } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  data: () => ({
    spinner: false,
    faenas: [
      { id: 0, nombre: "Todos" },
      { id: 1, nombre: "Cosecha" },
      { id: 3, nombre: "Smolt" },
      { id: 4, nombre: "Mortalidad" },
      { id: 5, nombre: "Baño" },
      { id: 6, nombre: "Vacuna" },
    ],
    upload: false,
    file: null,
    opcionReporte: 1,
    opcionFiltro: 0,
    color: "green",
    snackbar: false,
    validation_message: "",
    search: null,
    isLoading: false,
    empresa: {},
    empresas: [],
    centro: {},
    centros: [],
    cierre: 0,
    cierres: [],
    plantas: [],
    inicio: "",
    termino: "",
    menu1: false,
    menu2: false,
    url_excel: "",
    url_pdf: "",
    table_height: 600,
    can_download: false,
    partesListHeaders: [
      {
        text: "Acciones",
        align: "center",
        value: "actions",
        width: "100px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "N° Viaje",
        align: "center",
        sortable: true,
        value: "folio",
        width: "100px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Año",
        align: "center",
        sortable: true,
        value: "year",
        width: "100px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Mes",
        align: "center",
        sortable: true,
        value: "month",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tipo Faena",
        align: "center",
        sortable: true,
        value: "tipo_parte",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Zarpe",
        align: "center",
        sortable: true,
        value: "fecha_zarpe",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Fecha Viaje",
        align: "center",
        sortable: true,
        value: "fecha_viaje",
        dataType: "Date",
        width: "130px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Wellboat",
        align: "center",
        sortable: true,
        value: "wellboat.nombre",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Empresa",
        align: "center",
        sortable: true,
        value: "empresa",
        width: "200px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Guia Desp.",
        align: "center",
        sortable: true,
        value: "guia_despacho",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Cliente Origen",
        align: "center",
        sortable: true,
        value: "centro_carga",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Jaula Origen",
        align: "center",
        sortable: true,
        value: "jaulas_centro",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Cliente Destino",
        align: "center",
        sortable: true,
        value: "centro_descarga",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Jaula Destino",
        align: "center",
        sortable: true,
        value: "jaulas_acopio",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Especie",
        align: "center",
        sortable: true,
        value: "la_especie",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "N° Peces Guía",
        align: "center",
        sortable: true,
        value: "peces_guia",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "N° Peces Contador",
        align: "center",
        sortable: true,
        value: "peces_contador",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "N° Peces Mort Viaje",
        align: "center",
        sortable: true,
        value: "peces_mort_viaje",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "N° Peces Mort Centro",
        align: "center",
        sortable: true,
        value: "peces_mort_centro",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "N° Peces Planta",
        align: "center",
        sortable: true,
        value: "peces_planta",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "N° Peces Total Viaje",
        align: "center",
        sortable: true,
        value: "peces_total",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Diferencia Conteo (N°)",
        align: "center",
        sortable: true,
        value: "diferencia_conteo_numero",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Diferencia Conteo (%)",
        align: "center",
        sortable: true,
        value: "diferencia_conteo_porcentaje",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Talla (Kg) Pez Centro",
        align: "center",
        sortable: true,
        value: "talla_pez_centro",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Talla (Kg) Pez Planta Neto",
        align: "center",
        sortable: true,
        value: "talla_pez_planta",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Desviación Máxima de Carga (N°)",
        align: "center",
        sortable: true,
        value: "desviacion_maxima",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Diferencia Planta vs Desviacion Maxima (N°)",
        align: "center",
        sortable: true,
        value: "dif_planta_vs_desv_maxima",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Ton Compensar",
        align: "center",
        sortable: true,
        value: "ton_compensar",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Talla (Kg) Pez % Blod",
        align: "center",
        sortable: true,
        value: "talla_pez_blod",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Talla (Kg) Pez Total Planta",
        align: "center",
        sortable: true,
        value: "talla_total_planta",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Total Carga Biomasa (Kg)",
        align: "center",
        sortable: true,
        value: "total_carga_biomasa",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Total Carga Densidad (m3)",
        align: "center",
        sortable: true,
        value: "total_carga_densidad",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      /*
        {
          text: 'Observaciones',
          align: 'center',
          sortable: true,
          value: 'observaciones',
          width: "150px",
          class: "blue-grey darken-4 white--text"
        },
        */
      {
        text: "Carga ETA (hr)",
        align: "center",
        sortable: true,
        value: "carga_eta",
        width: "180px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Carga Inicio (hr)",
        align: "center",
        sortable: true,
        value: "carga_inicio",
        width: "180px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Carga Fin (hr)",
        align: "center",
        sortable: true,
        value: "carga_termino",
        width: "180px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Traslado Inicio (hr)",
        align: "center",
        sortable: true,
        value: "traslado_inicio",
        width: "180px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Traslado Fin (hr)",
        align: "center",
        sortable: true,
        value: "traslado_termino",
        width: "180px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Descarga ETA (hr)",
        align: "center",
        sortable: true,
        value: "descarga_eta",
        width: "180px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Descarga Inicio (hr)",
        align: "center",
        sortable: true,
        value: "descarga_inicio",
        width: "180px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Descarga Fin (hr)",
        align: "center",
        sortable: true,
        value: "descarga_termino",
        width: "180px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tiempos de Carga - Espera",
        align: "center",
        sortable: true,
        value: "carga_espera",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tiempos de Carga - Duracion",
        align: "center",
        sortable: true,
        value: "carga_duracion",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tiempos de Traslado - Espera",
        align: "center",
        sortable: true,
        value: "traslado_espera",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tiempos de Traslado - Duracion",
        align: "center",
        sortable: true,
        value: "traslado_duracion",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tiempos de Descarga - Espera",
        align: "center",
        sortable: true,
        value: "descarga_espera",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tiempos de Descarga - Duracion",
        align: "center",
        sortable: true,
        value: "descarga_duracion",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Total",
        align: "center",
        sortable: true,
        value: "total",
        width: "150px",
        class: "blue-grey darken-4 white--text",
      },
      {
        text: "Tecnico a Cargo",
        align: "center",
        sortable: true,
        value: "tecnico_wellboat",
        width: "250px",
        class: "blue-grey darken-4 white--text",
      },
    ],
    partesListBody: [],
    permisos: {},
    especies: [],
    elegirCentro: false,
    item: {},
    muelles: [],
    campo: "carga",
  }),
  mounted() {
    this.empresas = this.$store.getters["empresa/getActive"];
    this.acopios = this.$store.getters["acopio/getActive"];
    this.especies = this.$store.getters["especie/getActive"];
    this.muelles = this.$store.getters["muelle/getActive"];
    this.plantas = this.$store.getters["planta/getAll"];
    this.buscarPermisos();
    this.inicio = this.moment().format("YYYY-MM-01");
    this.termino = this.moment().format("YYYY-MM-DD");
    this.loadCierres();
    //this.loadPartes();
    if (window.innerHeight < 768) {
      this.table_height = 450;
    }
    if (window.innerHeight > 1080) {
      this.table_height = 900;
    }
  },
  methods: {
    showModalCentro(item, campo) {
      this.elegirCentro = true;
      this.item = item;
      this.campo = campo;
    },
    actualizarCentro() {
      if (this.campo == "descarga") {
        this.item.centro_descarga = this.centro.nombre;
        this.item.destino_id = this.centro.id;
      }
      if (this.campo == "carga") {
        this.item.centro_carga = this.centro.nombre;
        this.item.origen_id = this.centro.id;
      }
      this.item.empresa = this.empresa.razon_social;
      this.item.empresas_id = this.empresa.id;
      // console.log('empresa',this.empresa,'centro',this.centro,'item',this.item)
      this.elegirCentro = false;
    },
    buscarPermisos() {
      if (this.user.menus == undefined) return {};
      let ruta = this.$router.currentRoute.name;
      this.user.menus.forEach((p) => {
        if (p.children != undefined) {
          p.children.forEach((ch) => {
            if (ch.path == ruta) {
              this.permisos = ch;
            }
          });
        }
        if (p.path == ruta) {
          this.permisos = p;
        }
      });
    },
    async loadCierres() {
      let url = `${this.base_url}cierres/lista/select`;
      await this.axios
        .get(url, this.headers_json)
        .then((response) => {
          this.cierres = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async loadPartes() {
      this.spinner = true;
      this.can_download = false;
      this.partesListBody = [];
      let url = `${this.base_url}cargas/bitacoras/reporte`;
      let data = {
        wellboats_id: this.user.wellboat.id,
        inicio: this.inicio,
        termino: this.termino,
        cierre: this.cierre,
        filtro: this.opcionFiltro,
      };
      await this.axios
        .post(url, data, this.headers_json)
        .then((response) => {
          let partes = response.data.body;
          partes.forEach((parte) => {
            parte.fecha_viaje = this.moment(parte.fecha_viaje).toDate();
            parte.carga_eta = this.moment(parte.carga_eta).toDate();
            parte.carga_inicio = this.moment(parte.carga_inicio).toDate();
            parte.carga_termino = this.moment(parte.carga_termino).toDate();
            parte.traslado_inicio = this.moment(parte.traslado_inicio).toDate();
            parte.traslado_termino = this.moment(
              parte.traslado_termino
            ).toDate();
            parte.descarga_eta = this.moment(parte.descarga_eta).toDate();
            parte.descarga_inicio = this.moment(parte.descarga_inicio).toDate();
            parte.descarga_termino = this.moment(
              parte.descarga_termino
            ).toDate();
            parte.select = false;
          });
          this.partesListBody = partes;
          //this.url_excel = this.storage_url + response.data.url_excel;
          this.url_excel = response.data.url;
          //this.url_pdf   = this.storage_url + response.data.url_pdf;
          this.showSnackBar(false);
          if (this.partesListBody.length > 0) this.can_download = true;
        })
        .catch((error) => {
          let response = error.response;
          //let msg = response.data.data;
          this.validation_message =
            response.status + ": " + response.data.message;
          this.showSnackBar(true);
          console.log(error);
        });
      this.spinner = false;
    },
    async reporte_sw_nuevo_a_excel() {
      let url = `${this.base_url}reporte_sw_nuevo_a_excel`
      let body = this.partesListBody.map(item => {
        return {
          folio: item.folio,
          tipo_parte: item.tipo_parte,
          year: item.year,
          month: item.month,
          fecha_viaje: this.moment(item.fecha_viaje).format("YYYY-MM-DD HH:mm:ss"),
          wellboat: item.wellboat,
          empresa: item.empresa,
          guia_despacho: item.guia_despacho,
          centro_carga: item.centro_carga,
          jaulas_centro: item.jaulas_centro,
          centro_descarga: item.centro_descarga,
          jaulas_acopio: item.jaulas_acopio,
          especie: item.especie,
          peces_guia: item.peces_guia,
          peces_contador: item.peces_contador,
          peces_mort_viaje: item.peces_mort_viaje,
          peces_mort_centro: item.peces_mort_centro,
          peces_planta: item.peces_planta,
          peces_total: item.peces_total,
          diferencia_conteo_numero: item.diferencia_conteo_numero,
          diferencia_conteo_porcentaje: item.diferencia_conteo_porcentaje,
          talla_pez_centro: item.talla_pez_centro,
          talla_pez_planta: item.talla_pez_planta,
          desviacion_maxima: item.desviacion_maxima,
          dif_planta_vs_desv_maxima: item.dif_planta_vs_desv_maxima,
          ton_compensar: item.ton_compensar,
          talla_pez_blod: item.talla_pez_blod,
          talla_total_planta: item.talla_total_planta,
          total_carga_biomasa: item.total_carga_biomasa,
          total_carga_densidad: item.total_carga_densidad,
          carga_eta: this.moment(item.carga_eta).format("YYYY-MM-DD HH:mm:ss"),
          carga_inicio: this.moment(item.carga_inicio).format("YYYY-MM-DD HH:mm:ss"),
          carga_termino: this.moment(item.carga_termino).format("YYYY-MM-DD HH:mm:ss"),
          traslado_inicio: this.moment(item.traslado_inicio).format("YYYY-MM-DD HH:mm:ss"),
          traslado_termino: this.moment(item.traslado_termino).format("YYYY-MM-DD HH:mm:ss"),
          descarga_eta: this.moment(item.descarga_eta).format("YYYY-MM-DD HH:mm:ss"),
          descarga_inicio: this.moment(item.descarga_inicio).format("YYYY-MM-DD HH:mm:ss"),
          descarga_termino: this.moment(item.descarga_termino).format("YYYY-MM-DD HH:mm:ss"),
          carga_espera: item.carga_espera,
          carga_duracion: item.carga_duracion,
          traslado_espera: item.traslado_espera,
          traslado_duracion: item.traslado_duracion,
          descarga_espera: item.descarga_espera,
          descarga_duracion: item.descarga_duracion,
          total: item.total,
          tecnico_wellboat: item.tecnico_wellboat,
        }
      })
      await this.axios.post(url, body, this.headers_json).then(response => {
        this.url_excel = response.data
        this.download()
      })
    },
    async save(item) {
      //console.log('fecha_viaje : ' + item.fecha_viaje);
      // let especie = item.la_especie;
      // if (typeof item.la_especie == 'object') {
      //   especie = item.la_especie.nombre;
      // }
      let url = `${this.base_url}cargas/bitacoras/reporte/grabar`;
      let data = {
        partes_id: item.partes_id,
        parte_descarga_id: item.parte_descarga_id,
        cargas_id: item.cargas_id,
        traslados_id: item.traslados_id,
        descargas_id: item.descargas_id,
        descargas_plantas_id: item.descargas_plantas_id,
        folio: item.folio,
        year: item.year,
        month: item.mes,
        fecha: this.moment(item.fecha_viaje).format("YYYY-MM-DD"),
        wellboat: item.wellboat,
        wellboats_id: item.wellboats_id,
        // empresa: item.parte.empresa.razon_social,
        // empresa: item.empresa ? item.empresa : item.parte.empresa.razon_social,
        empresa: item.empresa,
        empresas_id: item.empresas_id,
        //se setean solo si cambia el origen o destino
        origen_id: item.origen_id,
        destino_id: item.destino_id,
        tipo_destino: item.tipo_destino,
        //se setean solo si cambia el origen o destino

        guia: item.guia_despacho,
        centro: item.centro_carga,
        jaula_origen: item.jaulas_centro,

        acopio:
          item.centro_descarga instanceof Object
            ? item.centro_descarga.nombre
            : item.centro_descarga,
        acopios_id: item.centro_descarga.id,

        jaula_destino: item.jaulas_acopio,
        especie: item.especie,
        peces_guia: item.peces_guia,
        peces_contador: item.peces_contador,
        mort_viaje: item.peces_mort_viaje,
        mort_centro: item.peces_mort_centro,
        peces_planta: item.peces_planta,
        peces_total: item.peces_total,
        diferencia_conteo_numero: item.diferencia_conteo_numero,
        diferencia_conteo_porcentaje: item.diferencia_conteo_porcentaje,
        talla_pez_centro: item.talla_pez_centro,
        talla_pez_planta: item.talla_pez_planta,
        desviacion_maxima: item.desviacion_maxima,
        dif_planta_vs_desv_maxima: item.dif_planta_vs_desv_maxima,
        ton_compensar: item.ton_compensar,
        talla_pez_blod: item.talla_pez_blod,
        talla_total_planta: item.talla_total_planta,
        total_biomasa: item.total_carga_biomasa,
        densidad_carga: item.total_carga_densidad,
        observaciones: item.observaciones,
        carga_eta: this.moment(item.carga_eta).format("YYYY-MM-DD HH:mm:ss"),
        carga_inicio: this.moment(item.carga_inicio).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        carga_fin: this.moment(item.carga_termino).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        traslado_inicio: this.moment(item.traslado_inicio).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        traslado_fin: this.moment(item.traslado_termino).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        descarga_eta: this.moment(item.descarga_eta).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        descarga_inicio: this.moment(item.descarga_inicio).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        descarga_fin: this.moment(item.descarga_termino).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        carga_espera: item.carga_espera,
        carga_duracion: item.carga_duracion,
        traslado_espera: item.traslado_espera,
        traslado_duracion: item.traslado_duracion,
        descarga_espera: item.descarga_espera,
        descarga_duracion: item.descarga_duracion,
        total_horas: item.total,
        tecnico: item.tecnico_wellboat,
      };
      //console.log("data", data);
      let response = null;
      try {
        response = await this.axios.post(url, data, this.headers_json);
        const index = this.partesListBody.findIndex(
          (p) => p.cargas_id == item.cargas_id && p.parte_descarga_id == item.parte_descarga_id
        );
        //console.log(response.data);
        this.$set(this.partesListBody[index],'desviacion_maxima',response.data.data.desviacion_maxima);
        this.$set(this.partesListBody[index],'dif_planta_vs_desv_maxima',response.data.data.dif_planta_vs_desv_maxima);
        //console.log(response);
        this.showSnackBar(false);
        this.can_download = false;
      } catch (error) {
        response = error.response;
        let msg = response.data.data;
        this.validation_message =
          response.status + ": " + response.data.message + ". " + msg;
        this.showSnackBar(true);
      }
    },
    download() {
      window.open(this.url_excel);

      this.can_download = false;
    },
    downloadPDF(item) {
      let st_url = this.storage_url.replace("public", "docs");
      let pdf_url = st_url + item.pdf;
      //console.log(pdf_url);
      window.open(pdf_url);
    },
    setCierre() {
      if (this.opcionReporte == 1) this.cierre = 0;
    },
    showSnackBar(error) {
      if (error) {
        this.color = "red";
      } else {
        this.color = "green";
        this.validation_message = "Ejecutado exitosamente!";
      }
      this.snackbar = true;
    },
    async uploadServiciosWellboat() {
      this.headers.headers["Content-Type"] = "multipart/form-data";
      let url = `${this.base_url}uploads/servicios_wellboat`;
      let formData = new FormData();
      formData.append("excel", this.file);
      let response = null;
      try {
        response = await this.axios.post(url, formData, this.headers);
        this.file = null;
        this.upload = false;
        this.showSnackBar(false);
      } catch (error) {
        response = error.response;
        let msg = response.data.data;
        this.validation_message =
          response.status + ": " + response.data.message + ". " + msg;
        this.showSnackBar(true);
      }
    },
    ...mapMutations(["actualizarBaseUrl", "mostrarLoading", "ocultarLoading"]),
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    reformatDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("-");
      return `${year}-${month}-${day}`;
    },
    calcularPecesTotalViaje(item) {
      // const index = this.partesListBody.findIndex(p => p.id == item.id);
      const index = this.partesListBody.findIndex(
        (p) => p.cargas_id == item.cargas_id && p.parte_descarga_id == item.parte_descarga_id
      );
      let total =
        parseInt(item.peces_mort_viaje) +
        parseInt(item.peces_mort_centro) +
        parseInt(item.peces_planta);
      item.peces_total = total;
      this.$set(this.partesListBody, index, item);
      this.calcularDiferenciaConteo(item);
      this.calcularToneladasCompensar(item);
    },
    calcularToneladasCompensar(item) {
      this.calcularDesviacionPlantaMaxima(item);
      // const index = this.partesListBody.findIndex(p => p.id == item.id);
      const index = this.partesListBody.findIndex(
        (p) => p.cargas_id == item.cargas_id && p.parte_descarga_id == item.parte_descarga_id
      );
      let pdm = item.dif_planta_vs_desv_maxima;
      let tpps = `${item.talla_pez_planta}`;
      if (
        typeof item.talla_pez_planta == "string" &&
        item.talla_pez_planta.indexOf(",")
      ) {
        tpps = item.talla_pez_planta.replace(",", ".");
      }
      let tpp = parseFloat(tpps);
      let tons = (parseFloat(pdm) * parseFloat(tpp)) / 1000;
      item.ton_compensar = tons.toFixed(2) >= 0 ? tons.toFixed(2) : 0;
      this.$set(this.partesListBody, index, item);
      this.calcularTallaPezBlod(item);
    },
    calcularDiferenciaConteo(item) {
      // const index = this.partesListBody.findIndex(p => p.id == item.id);
      const index = this.partesListBody.findIndex(
        (p) => p.cargas_id == item.cargas_id && p.parte_descarga_id == item.parte_descarga_id
      );
      let dif = parseInt(item.peces_total) - parseInt(item.peces_contador);
      item.diferencia_conteo_numero = Math.round(dif);
      let dif_porc = (dif / item.peces_contador) * 100;
      item.diferencia_conteo_porcentaje = dif_porc.toFixed(2);
      this.$set(this.partesListBody, index, item);
    },
    calcularDesviacionMaxima(item) {
      // const index = this.partesListBody.findIndex(p => p.id == item.id);
      const index = this.partesListBody.findIndex(
        (p) => p.cargas_id == item.cargas_id && p.parte_descarga_id == item.parte_descarga_id
      );
      let dvm = item.peces * 0.95;
      item.desviacion_maxima = Math.round(dvm);
      this.$set(this.partesListBody, index, item);
    },
    calcularDesviacionPlantaMaxima(item) {
      // const index = this.partesListBody.findIndex(p => p.id == item.id);
      const index = this.partesListBody.findIndex(
        (p) => p.cargas_id == item.cargas_id && p.parte_descarga_id == item.parte_descarga_id
      );
      let dm = parseFloat(item.desviacion_maxima);
      let pt = parseFloat(item.peces_total);
      let dvmp = dm - pt;
      item.dif_planta_vs_desv_maxima = dvmp.toFixed(2);
      this.$set(this.partesListBody, index, item);
    },
    calcularTallaPezBlod(item) {
      // const index = this.partesListBody.findIndex(p => p.id == item.id);
      const index = this.partesListBody.findIndex(
        (p) => p.cargas_id == item.cargas_id && p.parte_descarga_id == item.parte_descarga_id
      );
      let tpps = `${item.talla_pez_planta}`;
      if (
        typeof item.talla_pez_planta == "string" &&
        item.talla_pez_planta.indexOf(",")
      ) {
        tpps = item.talla_pez_planta.replace(",", ".");
      }
      let tpc = parseFloat(item.talla_pez_centro);
      let tpp = parseFloat(tpps);
      let tpb = ((tpc - tpp) / tpc) * 100;
      item.talla_pez_blod = tpb.toFixed(3);
      this.$set(this.partesListBody, index, item);
      this.calcularTallaPezPlanta(item);
    },
    calcularTallaPezPlanta(item) {
      // const index = this.partesListBody.findIndex(p => p.id == item.id);
      const index = this.partesListBody.findIndex(
        (p) => p.cargas_id == item.cargas_id && p.parte_descarga_id == item.parte_descarga_id
      );
      let tpc = item.talla_pez_centro;
      let tpb = item.talla_pez_blod;
      let tpp = parseFloat(tpc) + (parseFloat(tpc) * parseFloat(tpb)) / 100;
      item.talla_total_planta = tpp.toFixed(3);
      this.$set(this.partesListBody, index, item);
    },
    calcularTiempoCarga(item) {
      /* item.carga_espera = this.restarHoras(item.carga_eta, item.carga_inicio);
      item.carga_duracion = this.restarHoras(
        item.carga_inicio,
        item.carga_termino
      ); */
      this.calcularTiempoTotal(item);
    },
    calcularTiempoTraslado(item) {
      /* item.traslado_espera = this.restarHoras(
        item.carga_termino,
        item.traslado_inicio
      );
      item.traslado_duracion = this.restarHoras(
        item.traslado_inicio,
        item.traslado_termino
      ); */
      this.calcularTiempoTotal(item)
    },
    calcularTiempoDescarga(item) {
      /* item.descarga_espera = this.restarHoras(
        item.descarga_eta,
        item.descarga_inicio
      );
      item.descarga_duracion = this.restarHoras(
        item.descarga_inicio,
        item.descarga_termino
      ); */
      this.calcularTiempoTotal(item);
    },

    async calcularTiempoTotal(item) {
      let body = {
        carga_eta: this.moment(item.carga_eta).format('YYYY-MM-DD HH:mm:ss'),
        carga_inicio: this.moment(item.carga_inicio).format('YYYY-MM-DD HH:mm:ss'),
        carga_termino: this.moment(item.carga_termino).format('YYYY-MM-DD HH:mm:ss'),
        traslado_inicio: this.moment(item.traslado_inicio).format('YYYY-MM-DD HH:mm:ss'),
        traslado_termino: this.moment(item.traslado_termino).format('YYYY-MM-DD HH:mm:ss'),
        descarga_eta: this.moment(item.descarga_eta).format('YYYY-MM-DD HH:mm:ss'),
        descarga_inicio: this.moment(item.descarga_inicio).format('YYYY-MM-DD HH:mm:ss'),
        descarga_termino: this.moment(item.descarga_termino).format('YYYY-MM-DD HH:mm:ss'),
        tiempo_carga_contrato: item.tiempo_carga_contrato
      }
      await this.axios.post(`${this.base_url}cargas/calculo/total_horas`, body, this.headers_json).then(r => {

        let idx = this.partesListBody.findIndex(p => p.cargas_id == item.cargas_id && p.parte_descarga_id == item.parte_descarga_id)
        this.$set(this.partesListBody[idx], 'total', r.data.total_horas)
        this.$set(this.partesListBody[idx], 'carga_espera', r.data.tiempo_espera_carga)
        this.$set(this.partesListBody[idx], 'carga_duracion', r.data.tiempo_carga)
        this.$set(this.partesListBody[idx], 'traslado_espera', r.data.tiempo_espera_traslado)
        this.$set(this.partesListBody[idx], 'traslado_duracion', r.data.tiempo_traslado)
        this.$set(this.partesListBody[idx], 'descarga_espera', r.data.tiempo_espera_descarga)
        this.$set(this.partesListBody[idx], 'descarga_duracion', r.data.tiempo_descarga)
        //console.log('res',r)
      })
    },
    calcularTiempoTotalOld(item) {
      let sumar = parseInt(this.moment("1970-01-01").format("x"));
      let espera_carga = this.restarHoras(
        item.carga_eta,
        item.carga_inicio,
        true
      );
      let duracion_carga = this.restarHoras(
        item.carga_inicio,
        item.carga_termino,
        true
      );
      let espera_descarga = this.restarHoras(
        item.descarga_eta,
        item.descarga_inicio,
        true
      );
      let duracion_descarga = this.restarHoras(
        item.descarga_inicio,
        item.descarga_termino,
        true
      );

      let total =
        espera_carga +
        duracion_carga +
        espera_descarga +
        duracion_descarga +
        sumar;
      let maximo = parseInt(
        this.moment("1970-01-01 " + this.user.wellboat.tiempo_carga).format("x")
      );
      if (total > maximo) {
        let termino = this.moment(total).format("YYYY-MM-DD HH:mm:ss");
        let inicio = this.moment(maximo).format("YYYY-MM-DD HH:mm:ss");
        item.total = this.restarHoras(inicio, termino);
        return;
      }
      item.total = "00:00:00";
    },
    restarHoras(hora_inicio, hora_termino, millis = false) {
      let sumar = parseInt(this.moment("1970-01-01").format("x"));
      let inicio = this.moment(hora_inicio);
      let termino = this.moment(hora_termino);
      let diff = termino.diff(inicio) + sumar;
      if (millis) return termino.diff(inicio);
      let dias = parseInt(this.moment(diff).format("D")) - 1;
      let horas = parseInt(this.moment(diff).format("H"));
      if (dias > 0) horas += 24 * dias;
      return (
        horas.toString().padStart(2, "0") + this.moment(diff).format(":mm:ss")
      );
    },
    formatNumber(numero, decimales = 2, campo = null) {
      //, checknegative = false){
      if (campo != null) console.log(numero);
      if (isNaN(numero) || numero == undefined) return 0;
      let arrNum = numero.toString().split(".");
      if (arrNum[1] == undefined) arrNum[1] = "";
      let num = arrNum[0];
      if (campo != null) console.log(arrNum[1]);

      if (!isNaN(num)) {
        if (num.length == 4 && num.indexOf("-") == 0) {
          // no se hace nada
        } else {
          num = num
            .split("")
            .reverse()
            .join("")
            .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
          num = num
            .split("")
            .reverse()
            .join("")
            .replace(/^[.]/, "");
        }
        if (decimales > 0) num += ",";
        return num + arrNum[1].padEnd(decimales, "0");
      }
      return "0";
    },
    ...mapMutations(["mostrarLoading", "ocultarLoading"]),
  },
  computed: {
    ...mapState([
      "storage_url",
      "loading",
      "base_url",
      "headers",
      "headers_json",
      "user",
    ]),
    inicio_formated() {
      return this.formatDate(this.inicio);
    },
    termino_formated() {
      return this.formatDate(this.termino);
    },
  },
  components: {
    DatePicker,
  },
};
</script>
